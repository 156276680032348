import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import jwt_decode from 'jwt-decode'

import {
  getLocalToken,
  getSystemUrl,
  getUserProfile,
  removeLocalToken,
  removeSystemUrl,
  setSystemUrl,
  setUserProfile,
} from '../services/localStorage'

import loginLeft from '../assets/images/loginBanner.svg'
import { Loader } from '../components/Loader'
import { userLogin } from '../api/user'
import {
  ErrorMessage,
  Group,
  InputText,
  Label,
} from '../components/FigmaBasicComponents/index'
import { cpfMask } from '../utils/string'
import { useFormik } from 'formik'
import { Button } from '@mui/material'
import { listProfiles } from '../api/profile'
import { setUserData } from '../utils/setUserData'

export interface JWT {
  exp: number
}

export function LoginTest() {
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [sysUrl, setSysUrl] = useState('')
  const [cpf, setCpf] = useState('')
  const [password, setPassword] = useState('')
  const [notAuth, setNotAuth] = useState(false)

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      cpf: '',
      password: '',
    },
    validationSchema: yup.object({
      cpf: yup.string().required('O campo cpf é obrigatório.'),
      // .length(14, 'O campo cpf deve ter 11 dígitos.'),
      password: yup.string().required('O campo senha é obrigatório.'),
    }),
    onSubmit: () => {
      handleLogin(cpf, password)
    },
  })

  useEffect(() => {
    const fetchUrl = async () => {
      const params: any = queryString.parse(window.location.search)
      const cookUrl = await getSystemUrl()
      if (params.url) {
        await setSystemUrl(params.url)
        setSysUrl(params.url)
      }

      if (cookUrl) {
        await setSysUrl(cookUrl)
      }
    }

    fetchUrl().then(async () => {
      document.title = 'Login'
      setToken(getLocalToken())
      if (token) {
        try {
          const jwt: JWT = jwt_decode(token)

          if (Date.now() >= jwt.exp * 1000) {
            removeLocalToken()
          }
          if (sysUrl || getSystemUrl() !== '') {
            await removeSystemUrl()
            
            if(getUserProfile() === '' || getUserProfile() === "undefined") {
              setUserData()
              
              listProfiles().then((res)=> {
                setUserProfile(res.results[0].ids[0])
                
                res.results.forEach((profile: any) => {
                  if(profile.perfil === "Pre-cadastro") {
                    setUserProfile(profile.ids[0])
                  }
                })
              })
            }

            window.location.href = `https:\\//${sysUrl || getSystemUrl()}`
            return
          }

          setUserData()
          navigate('/pre-select')
        } catch (err) {
          setLoading(false)
          console.log(err)
        }
      } else {
        setLoading(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token])

  const handleLogin = async (cpf: string, password: string) => {
    setLoading(true)
    userLogin(cpf, password)
      .then((res) => {
        if (!res) {
          setNotAuth(true)
          setLoading(false)
          return
        }
        
        setUserData()
        return navigate('/pre-select')
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  return (
    <div
      id='login-0'
      className='grid grid-cols-1 md:grid-cols-2 w-[100%]'
      style={{ height: '100%', display: loading ? 'none' : '' }}
    >
      <div id='login-1' className='mx-[auto] my-[auto] w-[100%] px-[20px]'>
        <div>
        <p
          id='login-2'
          className='mb-[35px] text-[#002577] text-[20px]'
          style={{ fontWeight: '700' }}
        >
          Bem-vindo(a) ao Sistema de Proteção de Trajetórias Escolares (SPTE).
        </p>

        <form onSubmit={formik.handleSubmit} className='max-w-[350px]'>
          <div className='flex flex-col gap-[16px]'>
            <Group mdFlexDirection='col'>
              <Label>CPF</Label>
              <InputText
                id='cpf'
                type='text'
                name='cpf'
                placeholder='Digite aqui o CPF'
                onChange={(e: any) => {
                  setCpf(cpfMask(e.target.value))
                  formik.handleChange(e)
                  setNotAuth(false)
                }}
                value={cpf}
              />
              <ErrorMessage error={formik.touched.cpf && formik.errors.cpf}>
                {formik.touched.cpf && formik.errors.cpf}
              </ErrorMessage>
            </Group>

            <Group mdFlexDirection='col'>
              <Label>Senha</Label>
              <InputText
                id='password'
                type='password'
                name='password'
                placeholder='Digite sua senha'
                onChange={(e: any) => {
                  setPassword(e.target.value)
                  formik.handleChange(e)
                  setNotAuth(false)
                }}
                value={password}
              />
              <ErrorMessage
                error={formik.touched.password && formik.errors.password}
              >
                {formik.touched.password && formik.errors.password}
              </ErrorMessage>
            </Group>
          </div>

          <ErrorMessage error={notAuth}>
            {notAuth && 'Cpf ou senha inválidos'}
          </ErrorMessage>

          <Button
            id='login-3'
            type='submit'
            variant='contained'
            color='primary'
            className='mt-[10px]'
          >
            Entrar
          </Button>
        </form>
        </div>
        {/* <footer className='absolute bottom-12 text-center text-profile w-[calc(50%-32px)]'>
          Deseja entrar em contato? <a href='/login-contato' className='font-semibold text-brand-primary-400 cursor-pointer'>
            Fale conosco
          </a>
        </footer> */}
      </div>
      <div
        id='login-4'
        className='hidden md:block'
        style={{
          backgroundImage: `url(${loginLeft})`,
          height: '100%',
          backgroundRepeat: 'no-repeat, repeat',
          backgroundSize: 'cover',
        }}
      ></div>
      <Loader loading={loading} />
    </div>
  )
}
